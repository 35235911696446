<template>
  <div class="activity">
    <div class="swiper-box">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item v-for="(item,index) in activswiper" :key="index" >
          <img :src="global.imgUrl + item.imgUrl" alt="" @click="swiperLinkUrl(item.linkUrl)"/>
          <div class="swiper-title">{{ item.title }}</div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="activity-type-box">
      <!-- <div class="header">活动类型:</div> -->
      <div
        class="activity-type-label"
        @click="changelabel(item)"
        :class="item.title == activetext ? 'active-label' : null"
        v-for="(item,index) in activitylist" :key="index"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="curriculum-box">
      <div class="curriculum-items" v-for="(adItem,adIndex) in activdatalist" :key="adIndex">
        <img
          :src="global.imgUrl + adItem.imgUrl"
          alt=""
          @click="godetails(adItem,6)"
        />
        <p class="title" @click="godetails(adItem,6)">
          {{ adItem.title }}
        </p>
        <div class="scale-box">
          <div class="city">{{ adItem.articleFields[0].fieldValue }}</div>
          <div class="people">{{ adItem.articleFields[3].fieldValue }}人</div>
          <div class="date">
            {{ adItem.articleFields[1].fieldValue.slice(0, 10) }}
          </div>
        </div>
        <!-- <div class="money">
          ￥{{ adItem.articleFields[2].fieldValue }}.00 <span>起</span>
        </div> -->
      </div>
    </div>
    <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="24"
      layout="prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination> -->
  </div>
</template>

<script>
import axios from "axios";
import {getactivitytype , getactivswiper ,getactivitylist,getactivitylisttype} from "@/api"
export default {
  name: "activity",
  data() {
    return {
      activitylist: [
        { title: "全部" },
        { title: "会议" },
        { title: "展览" },
        { title: "沙龙" },
        { title: "培训" },
      ],
      activetext: "全部",
      currentPage:1,
      activswiper:[],
      activdatalist:[],
      total:0,
    };
  },
  methods: {
    changelabel(item) {
      this.activetext = item.title;
      this.getactivitylist(item.id)
      // getactivitylisttype(item.id).then(res=>{
      //   this.activdatalist = res.data
      //   this.total = res.length
      // })
    },
    handleCurrentChange(e){
        console.log(e);
    },
    handleSizeChange(e){
        console.log(e);
    },
     swiperLinkUrl(item){
      window.open(item, "_blank");
    },
    godetails(item,type){
      console.log(item);
      localStorage.setItem('secondpath',JSON.stringify(this.$route.path));
      // this.$router.push(`/DetailsPage?id=${item.id}&type=${type}`) 
            let routeData = this.$router.resolve({
        name: 'DetailsPage',
        query: {
          id:item.id,
          type,
          title:item.title || item.Title
        },
      });
      window.open(routeData.href, "_blank");
    },
    start(){
      getactivitytype().then(res=>{
        this.activitylist = res.data
        console.log(this.activitylist)
      })
      getactivswiper(495).then(res=>{
        this.activswiper = res.data
        console.log(res,'轮播');
      })
      // getactivitylist().then(res=>{
      //   console.log(res,'列表');
      //   this.activdatalist = res.data
      //   this.total = res.length 
      // })
    },
   async getactivitylist(classifyId){
    getactivitylist(classifyId).then(res=>{
        console.log(res,'列表');
        this.activdatalist = res.data
        this.total = res.length 
      })
      // let res = await axios.get(
      //   `https://mcnki.obs.cn-east-2.myhuaweicloud.com/js/188-${classifyId}-0.js`
      // );
      // this.activdatalist = res.data
      // console.log(this.activdatalist)
    }
  },
  mounted(){
    this.start()
    this.getactivitylist(0)
  }
};
</script>

<style lang="less" scoped>
.activity {
  width: 1280px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 50px;
  .swiper-box {
    img {
      width: 100%;
      height: 100%;
    }
    /deep/ .el-carousel__container {
      height: 450px;
    }
    /deep/ .el-carousel__indicators--horizontal {
      left: 10%;
      bottom: 30px;
    }
    /deep/ .el-carousel__button {
      // 默认按钮的颜色大小等
      width: 24px;
      height: 4px;
      border: none;
      background: hsla(0, 0%, 100%, 0.4);
      opacity: 1;
    }
    /deep/ .is-active .el-carousel__button {
      // 选中图片的颜色
      background: #F08200;
      opacity: 1;
    }
    /deep/ .el-carousel__arrow {
      border-radius: 0;
    }
    /deep/ .el-carousel__arrow--right {
      right: 0;
      background: #0404044a;
    }
    /deep/ .el-carousel__arrow--left {
      left: 0;
      background: #0404044a;
    }
  }
  .activity-type-box {
    background: white;
    // margin-top: 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    .header {
      color: #ADACA9;
      font-size: 15px;
      font-weight: bold;
    }
    .activity-type-label {
      margin: 0 10px;
      font-size: 14px;
      padding: 8px 15px;
      cursor: pointer;
          border-radius: 3px;
    }
    .active-label {
      background: #314483;
      color: #fff;
      // font-weight: bold;
    }
  }
  .curriculum-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    .curriculum-items {
      width: 32%;
      background: white;
      margin-bottom: 20px;
      img {
        width: 410px;
        cursor: pointer;
      }
      .title {
        margin: 10px 15px;
        cursor: pointer;
        overflow: hidden;  /*超出部分隐藏*/
    white-space: nowrap;  /*禁止换行*/
    text-overflow: ellipsis; /*省略号*/

      }
      .scale-box {
        padding: 10px 15px;
        display: flex;
        border-bottom: 1px solid #e9e9e9;
        align-items: center;
        position: relative;
        .city,
        .people {
          background: #e9e9e973;
          padding: 5px 20px;
          font-size: 16px;
          color: #757575;
        }
        .city {
          margin-right: 20px;
        }
        .date {
          position: absolute;
          right: 15px;
          color: #9b9b9b;
        }
      }
      .money {
        text-align: right;
        padding: 10px 15px;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 18px;
        color: #eb4d37;
        span {
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
    .curriculum-items:hover{
       cursor: pointer;
       box-shadow: 0px 10px 10px #b3b7c8;
    background: #fafafa
    }
  }
  .el-pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    /deep/ li {
      margin: 0 8px !important;
      min-width: 36px !important;
      height: 34px;
      line-height: 36px;
    }
    /deep/ .active {
      color: white;
      background: #314483;
    }
    /deep/ li:hover {
      color: white;
      background: #314483;
    }
    /deep/ button {
      margin: 0 8px !important;
      height: 34px;
      line-height: 36px;
    }
    /deep/ .btn-next {
      padding: 0;
    }
    /deep/ .btn-prev {
      padding: 0;
    }
    /deep/ button:hover {
      color: white;
      background: #314483;
    }
    /deep/ span {
      margin-right: 30px;
      line-height: 36px;
    }
  }
}
</style>